import { formatPhoneNumberIntl, Value } from "react-phone-number-input"
import { isValidPhoneNumber } from "react-phone-number-input"

export function validatePhoneNumber(value: string) {
  return isValidPhoneNumber(value)
}

export function formatPhoneNumber(value: string) {
  // casting to Value here; we might want to look deeper into the parsing
  // options -- e.g., parsePhoneNumberFromString -- from
  // react-phone-number-input if we need to resolve phone number formatting
  // issues in the future
  return formatPhoneNumberIntl(value as Value)
}
